.header {
  height: 800px;
  font-family: "Poppins";
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
  color: rgb(3, 153, 131);

  &__text {
    width: 38%;
    h1 {
      font-size: 52px;
      font-weight: bold;
      color: rgb(3, 153, 131);
    }

    p {
      margin: 0;
    }
  }

  &__gallery {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    width: 50%;

    @media (max-width: 1080px) {
      width: 100%;
    }
  }

  &__gallery-img {
    width: 200px;
    padding: 5px;
    img {
      height: 160px;
      width: 100%;
    }
  }

  &__img {
    width: 60%;
    img {
      height: 100%;
      width: 100%;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    padding-top: 100px;

    &__text {
      width: 60%;
      h1 {
        font-size: 48px;
        font-weight: bold;
        color: rgb(3, 153, 131);
      }
    }

    &__img {
      width: 80%;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 450px) {
    height: 700px;
    &__text {
      width: 80%;
      h1 {
        font-size: 40px;
        font-weight: bold;
        color: rgb(3, 153, 131);
      }
    }

    &__img {
      width: 100%;
      font-size: 14px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }

}
