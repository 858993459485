.team-card {
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  // margin: 10px;s
  &__img {
    background-color: white;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;

    img {
      height: 100%;
      overflow: hidden;
    }
  }
  &__name {
    font-size: 20px;
    font-weight: bold;
  }
  &__designation {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
  &__link {
    font-size: 30px;
  }
}
