@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("../public/assets/fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
}

body {
  margin: 0;
  box-sizing: border-box;
  display: block;
  font-family: "Poppins", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
