.contact-form {
  margin: auto;
  width: 60%;

  @media screen and (max-width: 450px) {
    width: 80%;
  }
}

.form-control {
  color: white;
  background-color: rgb(10, 9, 61);
}

input:focus {
  background-color: rgb(10, 9, 61);
}

.form-control,
textarea,
.send-button {
  border-radius: 0px;
  margin: 10px;
  letter-spacing: 1px;
}
.send-button {
  width: 100%;
}

.contact-us {
  font-family: "Poppins";
  background-color: rgb(10, 9, 61);
  padding: 60px 0px;

  &__container {
    display: flex;
    margin: auto;
    align-items: center;
    width: 70%;
  }
  &__title {
    width: 60%;
    color: white;
    margin: auto;
    padding: 10px;
    h3 {
      font-size: 34px;
      font-weight: bold;
    }
    p {
      font-size: 18px;
    }
  }

  @media screen and (max-width: 1025px) {
    &__container {
      width: 85%;
    }
  }

  @media screen and (max-width: 450px) {
    &__container {
      flex-wrap: wrap;
    }

    &__title {
      width: 80%;
    }
  }
}
