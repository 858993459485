.media {
  padding: 20px 20px;
  // background-color: red;
  font-family: "Poppins";

  &__heading {
    text-align: center;
    font-size: 34px;
    font-weight: bold;
    margin-top: 60px;
  }

  &-container-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 20px auto;
  }

  &-container-2 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__youtube-thumbnail {
    margin: 30px;
    max-width: 350px;
    // margin: auto;

    &-text {
      width: 100%;
      padding: 10px 0px;
      line-height: 26px;
      font-size: 18px;
    }

    a {
      color: black;
    }
    // background-color: white;
  }

  &__news-thumbnail {
    margin: 10px;
    &-logo {
      background-color: rgb(183, 227, 219);
      height: 150px;
      width: 210px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
    }

    &-link {
      width: 210px;
      background-color: rgb(250, 250, 250);
      padding: 16px;
      font-size: 14px;

      a {
        color: black;
      }
    }
  }
  @media screen and (max-width: 450px) {
    &__heading {
      font-size: 30px;
      margin-top: 30px;
    }
  }
}
