.investers {
  font-family: "Poppins";
  margin: 100px 100px;

  &__heading {
    font-size: 40px;
    text-align: center;
    font-weight: bold;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__award {
    width: 150px;
    height: 150px;
    margin: 20px;
    // background-color: red;
  }

  &__img {
    height: 150px;
    width: 150px;
    position: relative;

    & img {
      max-width: 100%;
      max-height: 100%;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__title {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
}

.about-us {
  font-family: "Poppins";

  &__team-title {
    padding: 20px;
    text-align: center;
    h2 {
      font-weight: bold;
      font-size: 34px;
    }
  }

  &__team-heading {
    padding: 20px;
    text-align: center;
    h4 {
      font-weight: bold;
    }
  }
}

.img-undp {
  & img {
    height: 100px;
  }
}
