.why-us {
  margin: 50px 0px;
  font-family: "Poppins";
  width: 100%;
  &__title {
    text-align: center;
    width: 60%;
    margin: auto;
    color: rgb(10, 9, 61);
    h2 {
      font-size: 36px;
      font-weight: bold;
    }
    p {
      margin: auto;
      width: 80%;
      font-size: 18px;
      color: rgb(3, 153, 131);
    }
  }
  &__content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__card {
    width: 350px;
    height: 280px;
    padding: 24px;
    border-radius: 30px;
    margin: 20px;

    h6 {
      font-size: 24px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 1025px) {
    &__title {
      width: 80%;

      h2 {
        font-size: 34px;
      }
      p {
        font-size: 16px;
      }
    }
  }
}
