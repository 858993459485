.blogCard {
  font-family: "Poppins";
  width: 600px;
  @media (max-width: 700px) {
    margin: auto;
  }
  @media (min-width: 700px) {
    &__right {
      margin-left: auto;
    }
  }
  & a {
    text-decoration: none;
  }

  @media (max-width: 550px) {
    width: 80%;
  }

  padding: 40px;

  &__img {
    margin: 0 0 20px 0;
    overflow: hidden;
    & img {
      height:380px;
      width: 490px;
      object-fit: cover;
      transition: all 0.5s;
    }

    @media (max-width: 770px) {
      & img {
        width: 100%;
        object-fit: cover;
      }
    }
  }

  &:hover {
    cursor: pointer;
    & img {
      transform: scale(1.05);
    }
  }

  &__text {
    width: 90%;
    color: black;
    font-weight: 500;
    font-size: 26px;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    padding-bottom: 10px;
    & span::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.1em;

      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    & span:hover::after {
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
    }
    & span::after {
      opacity: 1;
      transform: translate3d(-100%, 0, 0);
    }
    & span:hover::after,
    & span:focus::after {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &__date {
    color: black;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 18px;
  }
}
