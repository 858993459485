.navbar {
  &--logo {
    height: 50px;

    img {
      height: 100%;
    }
  }
}

.navlink {
  font-family: "Poppins";
  color: rgb(3, 153, 131);
  text-decoration: none;
}
