.footer {
  padding: 40px;
  background-color: rgb(233, 233, 243);

  &__main-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (max-width: 450px) {
      justify-content: center;
      text-align: center;
    }
  }

  &__title {
    font-size: 16px;
    font-style: "Poppins";
    padding-bottom: 10px;
  }

  &__col {
    width: 300px;
    padding: 20px 0px;
  }

  &__link-group {
    display: flex;
    flex-direction: column;
  }

  &__link {
    color: grey;
    font-size: 14px;
    padding: 5px;
  }

  &__misc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    font-size: 14px;
  }

  &__logo {
    height: 40px;

    img {
      height: 100%;
    }
  }

  &__links {
    width: 400px;
    display: flex;
    justify-content: space-around;

    a {
      font-size: 24px;
      color: rgb(10, 9, 61);
    }
  }
}
