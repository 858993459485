.vision {
  background-color: rgb(3, 153, 131);
  color: white;
  // height: 300px;

  &__main-content {
    min-height: 250px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-evenly;
  }

  &__column {
    margin: auto 0;
    padding: 20px;
  }

  &__title {
    font-size: 36px;
    font-weight: bold;
    font-family: "Poppins";
  }

  &__text {
    width: 200px;
  }

  @media screen and (max-width: 450px) {
    padding: 40px 0px;
    &__title {
      margin-left: 20px;
    }
    &__text {
      margin-left: 20px;
    }
  }
  // display: flex;
  // height: 500px;
  // background-color: rgb(3, 153, 131);

  // &__child {
  //   color: black;
  //   background-color: white;
  //   width: 300px;
  //   height: 300px;
  //   padding: 10px;
  //   margin: 0;
  //   box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
  //   border-radius: 40px 40px 40px 40px;
  // }

  // &__img {
  //   width: 130px;
  //   img {
  //     width: 100%;
  //   }
  // }
  // &__heading {
  //   text-align: center;
  //   h2 {
  //     font-size: 20px;
  //     font-weight: bold;
  //   }
  // }

  // &__paragraph {
  //   text-align: center;
  //   font-size: small;
  // }
}
