.impact {
  font-family: "Poppins";
  background-color: rgb(233, 233, 243);
  &__title {
    color: rgb(10, 9, 61);
    text-align: center;
    margin: 0 auto;
    width: 60%;
    padding-top: 50px;

    h3 {
      font-size: 34px;
      font-weight: bold;
    }
    p {
      color: rgb(3, 153, 131);
      font-size: 18px;
    }
  }

  &__main-content {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    padding: 30px;
  }

  @media screen and (max-width: 800px) {
    &__title {
      width: 95%;
      padding-top: 50px;

      h3 {
        font-size: 30px;
        font-weight: bold;
      }
      p {
        color: rgb(3, 153, 131);
        font-size: 16px;
      }
    }

    &__main-content {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      padding: 30px;
    }
  }
}

.impact-card {
  text-align: left;
  margin: 12px 24px;
  padding: 12px;
  // border: 0.5px solid pink;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;

  &__text {
    width: 260px;
    padding: 10px;
  }

  &__img {
    padding: 10px;
    height: 80px;
    width: 80px;
    // border-radius: 20px;
    background-color: rgb(233, 233, 243, 0.5);

    img {
      height: 100%;
    }
  }

  &__title {
    text-align: center;
    // width: 200px;
    font-size: 20px;
    font-weight: bold;
  }

  &__caption {
    font-size: 15px;
    text-align: center;
  }
}
