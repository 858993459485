.about-us {
  margin-top: 100px;
  width: 100%;
  font-family: "Poppins";
  color: rgb(10, 9, 61);
  &__section-1 {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  &__section-2 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: left;
  }

  &__heading {
    width: 35%;
    h1 {
      font-size: 50px;
      font-weight: bold;
    }

    p {
      color: rgb(3, 153, 131);
    }
  }

  &__img {
    width: 55%;
    img {
      width: 80%;
    }
  }

  &__custom-text-color {
    color: rgb(3, 153, 131);
  }

  &__team {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 75%;
    margin: auto;
  }

  @media screen and (max-width: 800px) {
    &__heading {
      h1 {
        font-size: 42px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: 450px) {
    &__heading {
      width: 80%;
    }

    &__section-1 {
      display: flex;
      flex-direction: column;
    }

    &__section-2 {
      display: flex;
      flex-direction: column;
    }

    &__img {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}
