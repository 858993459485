.whoDoWeServe {
  width: 100%;
  font-family: "Poppins";
  margin: 60px 0px;

  &__title {
    text-align: center;

    h3 {
      font-size: 34px;
      font-weight: bold;
    }

    p {
      font-size: 18px;
      color: rgb(3, 153, 131);
    }
  }

  &__main-content {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
  }

  &__image-container {
    width: 230px;
    margin: auto;
    // padding: 20px;

    img {
      width: 100%;
    }
  }

  &__item {
    height: 200px;
    width: 250px;
    padding-top: 25px;
    border: 0.5px solid pink;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    position: relative;
    &:hover .whoDoWeServe__overlay {
      opacity: 0.8;

      cursor: pointer;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgb(10, 9, 61);

    a {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      color: white;
      text-decoration: none;
      font-weight: bold;
      font-size: 20px;
    }
  }
}
