.services {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  position: relative;
  font-family: "Poppins";

  &__title {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;

    h1 {
      font-size: 54px;
    }
  }
  &__service-card {
    width: 50%;
    height: 100%;
  }

  &__card-title {
    position: relative;
    top: 50%;
    left: 50%;
    color: white;
    width: fit-content;
    transform: translate(-50%, -50%);
  }

  &__card-1 {
    background-color:#305A58; 
    margin-top: 50px;

  }

  &__card-2 {
    margin-top: 50px;
    background-color: #0A093D;
    
  }

  &__image-container-1 {
    width: 100%;
    display: flex;
    height: 67%;
    padding: 15px;

    img {
      display: block;
      height: 90%;
      padding: 10px;
    }

    
  }


  &__image-container-2 {
    width: 80%;
    position: absolute;
    // display: flex; 
    height: 50%;
    bottom: -30%;
    left: -15%;
    margin-left: 70px;
    img {
      // display: block; 
      height: 67%;
      padding: 5px;
    }

    
  }
  &__image-container-3 {
    width: 48%;
    position: absolute;
    height: 50%;
    bottom: -30%;
    left: 50%;
    margin-left: 15px;
    img {
      // display: block; 
      height: 67%;
      padding: 5px;
    }

    
  }
  &__image-button{
    position: relative;
    // color: white;
    background-color: white;
    left: 28%;
    height: "40px";
    width:"280px";
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9); /* Add a light shadow */
    transition: box-shadow 0.3s ease;
  }
  &__image-button-2{
    position: relative;
    background-color: white;
    left: 42%;
    height: "40px";
    width:"280px";
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.9); /* Add a light shadow */
    transition: box-shadow 0.3s ease;
  }

  @media (max-width: 800px) {
    .services__image-container-1 img
    {
      width: 300px;
      height: 250px;
      position: absolute;
      top: 230px;
      padding-left: 60px;
    }
    .services__image-container-2 img
    {
      width: 220px;
      height: 250px;
    }

    .services__image-container-3 img
    {
      width: 220px;
      height: 250px;
    }
    .services__image-container-2 {
      height: 25%;
      width: 40%;
      bottom: -5.8%;
      left: -0%;
    }

    .services__title{
      top: 35%;
      width: 170px;
    }
    .services__image-container-3 {
      left: 58%;
      width: 38%;
      height: 40%;
      bottom: -18.8%;

    }
    .services__image-button{
      left:10%;
      top:54%;
      width: 180px !important;
      font-size: 12px;

    }
    .services__image-button-2 {
      left:10%;
      width: 180px !important;
      font-size: 12px;
      bottom: -147px !important;

    }
  }

  @media (max-width: 414px) {
    .services__image-container-1 img
    {
      width: 190px;
      height: 200px;
      position: absolute;
      top: 230px;
      padding-left: 5px;
    }
    .services__image-container-2 img
    {
      width: 120px;
      height: 250px;
    }

    .services__image-container-3 img
    {
      width: 110px;
      height: 250px;
    }
    .services__image-container-2 {
      bottom: -2.1%;
      left: -4%;
    }

    .services__title{
      top: 35%;
      width: 120px;
      font-size: 8px !important;

    }
    .services__image-container-3 {
      left: 57%;
      bottom: -17%;
    }
    .services__image-button{
      left: 2%;
      width: 120px !important;
      font-size: 7px;

    }
    .services__image-button-2 {
      left:4%;
      width: 120px !important;
      font-size: 8px;
      bottom: -35% !important;

    }
  }

  
}
