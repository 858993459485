.blog {
  // margin: 4rem;
  &__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 5rem 5rem;
    @media (max-width: 770px) {
      padding: 0;
    }
  } 

  &__head {
    color: black;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 18px;
    margin-top:140px ;
    text-align: center;
  }

  &__card-container {
    width: 50%;
    // float: right;
  }
}
