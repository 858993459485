
.product-details {
    &__row {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      padding: 5rem 5rem;
      @media (max-width: 770px) {
        padding: 0;
      }
    } 
  
    &__head {
      color: black;
      font-weight: 300;
      text-transform: uppercase;
      font-size: 18px;
      margin-top:140px ;
      text-align: center;
    }
  
    &__card-container {
      width: 50%;
      // float: right;
    }
  }
  .product-tile {
    width: 30%;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    background-color: #A0E1CA;
    transition: transform 0.2s ease; // Adding a transition for the animation

  &:hover {
    transform: scale(1.05); // Scale up the tile on hover
  }
  
    img {
      width: 100%;
      border-radius: 5px;
      height: 280px;
    }
  
    h3 {
      margin-top: 10px;
      font-size: 18px;
    }
  
    p {
      margin-top: 5px;
      font-size: 16px;
    }
  }

  .product-screen-class{
    margin-top:80px ;
  }
  
  .product-list {
    margin-top: 80px;
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  

  