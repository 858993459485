.our-products {
  font-family: "Poppins";
  &__header {
    height: 500px;
    margin-top: 100px;
    display: flex;
    align-items: center;
    color: rgb(3, 153, 131);
  }

  a {
    color: black;
  }
  &__heading {
    padding: 0px 100px;

    h1 {
      font-size: 54px;
      font-weight: bold;
    }

    p {
      font-size: 22px;
    }
  }
}

.product-card {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  margin-top: 50px;

  &__carousel {
    width: 100%;
    margin: auto;
  }

  &__main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    margin: auto;
    min-height: 300px;
  }
  &__image-name-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(168, 189, 189);
    opacity: 0;
    transition: opacity 500ms ease-in-out;
    text-align: center;
    color: rgb(19, 1, 1);
    top: 0px;
    left: 0px;
    font-weight: bold;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__my-image {
    height: 200px;
    width: 200px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &__image-container {
    position: relative;
    display: inline-block;

    margin: 5px 30px;
    padding: 12px;

    &:hover {
      .product-card__image-name-overlay {
        opacity: 0.6;
      }
    }

    &:hover img {
      opacity: 0.5;
    }
  }

  &__product-image {
    width: 50%;
    img {
      max-width: 100%;
      max-height: 200px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: bold;
    width: 155px;
  }

  &__details {
    font-size: 20px;
  }
}
