.software-services {
  width: 100%;
  font-family: "Poppins";
  margin-top: 100px;
  color: rgb(10, 9, 61);
  &__title {
    text-align: center;
    width: 80%;
    margin: auto;
    padding: 20px;

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  &__heading {
    font-weight: bold;
    font-size: 34px;

    @media screen and (max-width: 450px) {
      font-size: 30px;
    }
  }

  &__subheading {
    font-size: 18px;
    color: rgb(3, 153, 131);

    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
}

.product-item {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin: 40px 0px;

  &__content {
    width: 30%;

    h4 {
      margin-left: 20px;
      font-weight: bold;
      font-size: 28px;
    }

    ul {
      font-size: 18px;
      color: rgb(3, 153, 131);
    }
  }

  &__img {
    width: 60%;
    img {
      height: 100%;
      width: 90%;
      padding-left: 50px;
    }
  }

  @media screen and (max-width: 1025px) {
    &__content {
      width: 40%;
      padding-left: 20px;

      ul {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 450px) {
    flex-direction: column;

    &__content {
      width: 80%;
      padding: 0;
    }

    &__img {
      width: 90%;
    }
  }
}
