.packaging-products {
  font-family: "Poppins";
  margin-top: 100px;
  &__heading {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
  }

  &__products-container {
    width: 100%;
  }

  &__carousel {
    width: 100%;
    // height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  &__carousel-img {
    height: 200px;
    margin: 20px;

    img {
      max-height: 100%;
    }
  }
}

.products {
  // background-image: url("");
  font-family: "Poppins";
  // margin-top: 150px;
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 30px;
  }

  &__description {
    width: 35%;
    padding: 0 30px;
  }

  &__item {
    display: flex;
    margin: 30px 0;
    align-items: center;
  }

  &__gallery-and-text {
    display: flex;
    margin: 0px 20px;
    margin-bottom: 50px;
    align-items: center;
    @media (max-width: 1080px) {
      flex-direction: column;
    } // justify-content: ;;
  }

  &__text {
    width: 38%;
    margin: 50px;
    color: rgb(10, 9, 61);
    h2 {
      font-size: 48px;
      font-weight: bold;
    }
    p {
      font-size: 20px;
      color: rgb(3, 153, 131);
    }
    @media (max-width: 1080px) {
      width: 80%;
    }
  }

  &__gallery {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    width: 50%;

    @media (max-width: 1080px) {
      width: 100%;
    }
  }

  &__gallery-img {
    width: 200px;
    padding: 5px;
    img {
      width: 100%;
    }
  }

  &__service-illustration {
    width: 50%;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      max-height: 500px;
    }
  }

  &__circle {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #a4c1fa;
    margin-right: 20px;
  }

  &__img-container {
    // height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    margin: auto;

    @media (max-width: 425px) {
      flex-direction: column;
      width: 100%;
    }
    // transform: rotate(90deg);
    & img {
      width: 100%;
    }
  }

  &__img {
    width: 50%;

    @media (max-width: 425px) {
      // flex-direction: column;
      width: 100%;
    }
  }

  &__heading-tertiary {
    font-size: 25rem;
    font-weight: 500;
  }

  &__detail {
    font-size: 16px;
    font-weight: bold;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 50px;
    width: 100%;
  }

  &__list-item {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
  }

  &__icon {
    width: 80px;
    height: 80px;
    font-size: 25px;
    text-align: center;
    padding: 20px;
    background-color: rgb(10, 9, 61);
    border-radius: 100px;
    color: white;
    & svg {
      margin-top: 6px;
    }
    & img {
      height: 100%;
      width: 100%;
      margin-top: 5px;
    }
  }

  &__description {
    width: 100%;
    padding: 10px;
  }

  &__head {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    color: rgb(3, 153, 131);
  }

  &__why-plastic {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 50px;
    margin-top: 0;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__100vs1-img {
    width: 33%;

    & img {
      width: 100%;
    }

    @media (max-width: 768px) {
      width: 60%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  &__why-plastic-text {
    margin: 50px;
    color: rgb(10, 9, 61);
    width: 50%;

    @media (max-width: 768px) {
      width: 60%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  &__pretitle {
    font-size: 28px;
    text-transform: uppercase;
  }

  &__title {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__details {
    font-size: 16px;
    width: 100%;
  }
}

.heading-secondary-black {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
}

.hygiene {
  display: flex;
  margin: 100px 80px;

  &__text {
    width: 50%;

    h2 {
      font-size: 48px;
      font-weight: bold;
    }
  }
  &__img {
    width: 50%;

    img {
      display: block;
      margin: auto;
      width: 80%;
    }
  }

  &__icon {
    vertical-align: middle;
    padding: 5px;
  }
}
