.case-study-screen {
  font-family: "Poppins";
  &__header {
    margin: 0px 50px;
    margin-top: 100px;
    display: flex;
    align-items: center;
    // background-image: url("/public/assets/img/cafeterias.png");
    height: 500px;
    // color: white;
  }
}

.case-study {
  margin: 50px 50px;
  &__container {
    display: flex;
    align-items: center;
  }
  &__content {
    width: 50%;
  }

  &__testimonial {
    width: 30%;
    margin: auto;
    border: 1px solid lightpink;
    padding: 20px;
    border-radius: 30px;
    background-color: lightcyan;
  }

  &__challenges {
    width: 35%;
    margin: auto;
  }

  &__results {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__result-card {
    margin: 20px;
    height: 115px;
    width: 170px;
    padding: 15px;
    background-color: cyan;
  }

  &__result-card-title {
    font-size: 32px;
    font-weight: bold;
  }

  &__case-study__description {
    font-size: 24px;
  }
}
