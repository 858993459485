.partners {
  // background-color: rgb(10, 9, 61);
  // color: white;
  background-color: rgb(233, 233, 243);
  font-family: "Poppins";
  padding: 0px 0px 60px 0px;
  &-container {
    align-items: center;
    padding: 20px;
    width: 80%;
    margin: auto;
    // height: 300px;
  }

  &__carousel {
    width: 100%;
    margin: auto;
  }

  &__title {
    color: rgb(10, 9, 61);
    text-align: center;
    h3 {
      font-weight: bold;
      font-size: 34px;
    }
    p {
      font-size: 18px;
      color: rgb(3, 153, 131);
    }
  }

  &__main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    margin: auto;
    min-height: 300px;
    // height: 300px;
  }

  &__image-container {
    height: 70px;
    margin: 5px 30px;
    padding: 12px;

    img {
      height: 100%;
    }
  }

  &__image-container-2 {
    height: 125px;
    border: 1px solid grey;
    padding: 15px;

    img {
      height: 100%;
    }
  }
}
