.faq {
  
  &__gallery-img-container {
    display: flex;
    justify-content: center;
    margin-top: 65px; /* Add some top margin to separate from the navbar */
    img{
      height: 300px;
      width: 300px;
      object-fit: cover;
    }
  }


  &__container {
    font-family: "Poppins";
    padding: 5px;
    width: 80%;
    margin: 0 auto; /* Center the container horizontally */
    color: #0c938e;
    margin-top: 20px; /* Add some space between the image and the FAQ tabs */
  }
  
  &__tab {
    padding: 10px;
    // border-top: 2px solid gray;
    border-bottom: 4px solid gray;
    transition: all 1s;

    &:first-of-type {
      border-top: 4px solid gray;
    }
    &:hover {
      cursor: pointer;
    }
  }

  &__heading-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__icon {
    font-size: 25px;
    transition: all 1s ease;
    &-rotated {
      transform: rotate(225deg);
    }
  }

  &__title {
    font-size: 25px;
  }

  &__description {
    font-size: 16px;
    max-height: 50rem;
    transition: all 1s;
    overflow: hidden;
    margin-bottom: 1rem;
    white-space: pre-line;
    &-hidden {
      max-height: 0;
      margin-bottom: 0;
    }
  }
}
