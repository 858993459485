.testimonials {
  font-family: "Poppins";
  width: 100%;
  color: rgb(3, 153, 131);
  margin: 80px 0px;
  &__title {
    h3 {
      text-align: center;
      color: rgb(10, 9, 61);
      font-size: 34px;
      font-weight: bold;
    }

    margin-bottom: 30px;
  }

  &__testimonial {
    width: 40%;
    margin: auto;
    text-align: justify;
  }

  &__name {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 0px;
  }

  @media screen and (max-width: 1025px) {
    h3 {
      font-size: 30px;
    }
    &__testimonial {
      width: 80%;
    }
  }
}
